import React from "react";

const CennikMedycyna = props => {
  return (
    <div>
      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th>MEDYCYNA ESTETYCZNA</th>
            <th>CENA *</th>
          </tr>
          <tr>
            <td>Okolica podania</td>
            <td>cena zabiegu</td>
          </tr>
          <tr>
            <td>lwia zmarszczka (zmarszczka podłużna czoła) </td>
            <td>350,00 </td>
          </tr>
          <tr>
            <td>zmarszczki poziome czoła </td>
            <td>400,00 </td>
          </tr>
          <tr>
            <td>zmarszczki poziome + lwia zmarszczka </td>
            <td>500,oo -800,00 </td>
          </tr>
          <tr>
            <td>kurze łapki </td>
            <td>350,00 </td>
          </tr>
          <tr>
            <td>kurze łapki + lwia zmarszczka </td>
            <td>400,00 </td>
          </tr>
          <tr>
            <td>kurze łapki + zmarszczki poziome + lwia zmarszczka</td>
            <td>600,00 -900,00 </td>
          </tr>
          <tr>
            <td colSpan="2" />
          </tr>
          <tr>
            <td>zmarszczki nosa (zmarszczki królicze) </td>
            <td>350,00 </td>
          </tr>
          <tr>
            <td>podniesienie czubka nosa </td>
            <td>250,00  </td>
          </tr>
          <tr>
            <td>uśmiech dziąsłowy </td>
            <td>350,00 </td>
          </tr>
          <tr>
            <td>opadające kąciki ust </td>
            <td>300,00 </td>
          </tr>
          <tr>
            <td>broda </td>
            <td>250,00 -350,00 </td>
          </tr>
          <tr>
            <td colSpan="2" />
          </tr>
          <tr>
            <td>leczenie bruksizmu* </td>
            <td>800,00 </td>
          </tr>
          <tr>
            <td colSpan="2" />
          </tr>
          <tr>
            <td>Nadpotliwość pierwotna</td>
            <td />
          </tr>
          <tr>
            <td>pachy/stopy/dłonie</td>
            <td>od 1 400 </td>
          </tr>
        </tbody>
      </table>

      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th colSpan="3">Mezoterapia igłowa </th>
          </tr>
          <tr>
            <td>ilość zabiegów</td>
            <td>1 zabieg</td>
            <td>3 zabiegi</td>
          </tr>
          <tr>
            <td colSpan="3">Twarz</td>
          </tr>

          <tr>
            <td>koktajl witaminowy</td>
            <td>275</td>
            <td>650</td>
          </tr>
          <tr>
            <td>kwas hialuronowy</td>
            <td>375</td>
            <td>950</td>
          </tr>
          <tr>
            <td>okolica oczu</td>
            <td>350</td>
            <td>800</td>
          </tr>
          <tr>
            <td colSpan="3">Dekolt</td>
          </tr>

          <tr>
            <td>koktajl witaminowy</td>
            <td>275</td>
            <td>650</td>
          </tr>
          <tr>
            <td>kwas hialuronowy</td>
            <td>375</td>
            <td>950</td>
          </tr>
          <tr>
            <td>zabiegi na twarz i dekolt jednocześnie</td>
            <td colSpan="2">
              cena ustalana indywidualnie podczas konsultacji z lekarzem
            </td>
          </tr>
          <tr>
            <td colSpan="3">Skóra owłosiona głowy</td>
          </tr>

          <tr>
            <td>terapia wzmacniająca dr. Cyj</td>
            <td>450</td>
            <td>1200</td>
          </tr>
          <tr>
            <td>Koktajl czynników wzrostu i białek biomimetycznych</td>
            <td>275</td>
            <td>900</td>
          </tr>
        </tbody>
      </table>

      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th colSpan="2">Lipoliza iniekcyjna</th>
          </tr>
          <tr>
            <td>Lipoliza iniekcyjna</td>
            <td>1 ampułka</td>
          </tr>
          <tr>
            <td>Aqualyx</td>
            <td>250 </td>
          </tr>
          <tr>
            <td>Dermastabilon</td>
            <td>230 </td>
          </tr>
          <tr>
            <td>Belkyra - zabieg na podwójny podbródek</td>
            <td>2 000 </td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <td>leczenie cellulitu</td>
            <td>275 </td>
          </tr>
        </tbody>
      </table>

      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th colSpan="2">Wypełniacze z kwasem hialuronowym TEOXANE®</th>
          </tr>
          <tr>
            <td>Miejsce podania</td>
            <td>cena za 1 ml preparatu</td>
          </tr>
          <tr>
            <td>usta</td>
            <td>750  -900 </td>
          </tr>
          <tr>
            <td>wolumetria policzków</td>
            <td>700 </td>
          </tr>
          <tr>
            <td>broda</td>
            <td>800</td>
          </tr>
          <tr>
            <td>nos</td>
            <td>750</td>
          </tr>
          <tr>
            <td>dolina łez z redukcją cieni pod oczami</td>
            <td>900</td>
          </tr>
          <tr>
            <td>dół skroniowy</td>
            <td>800</td>
          </tr>
          <tr>
            <td>lwia zmarszczka</td>
            <td>800</td>
          </tr>
          <tr>
            <td>linia żuchwy</td>
            <td>800</td>
          </tr>
          <tr>
            <td>linie marionetki</td>
            <td>750</td>
          </tr>
          <tr>
            <td>bruzdy nosowo-wargowe</td>
            <td>800</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <th colSpan="2">
              Cena za ponadstandardową ilość preparatu lub okolic
            </th>
          </tr>
          <tr>
            <td>dodatkowy 1 ml</td>
            <td>-20% do od ceny podstawowej</td>
          </tr>
          <tr>
            <td>2 okolice na 1 zabiegu</td>
            <td>-10% od ceny podstawowej</td>
          </tr>
        </tbody>
      </table>

      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th colSpan="3">Zabiegi nićmi PDO</th>
          </tr>
          <tr>
            <td>Nici MONO</td>
            <td>Ilość nici</td>
            <td>cena zabiegu</td>
          </tr>
          <tr>
            <td rowSpan="5" />
            <td>10 sztuk (5 na 1 stronę)</td>
            <td>420.00 </td>
          </tr>
          <tr>
            <td>20 sztuk (10 na 1 stronę)</td>
            <td>960.00 </td>
          </tr>
          <tr>
            <td>40 sztuk ( 2o na 1 stronę)</td>
            <td>1,680.00 </td>
          </tr>
          <tr>
            <td>dodatkowa nić</td>
            <td>40.00 </td>
          </tr>
          <tr>
            <td>Niestandardowa ilość nici</td>
            <td>cenę ustala lekarz</td>
          </tr>
          <tr>
            <td colSpan="3" />
          </tr>
          <tr>
            <td>Nici SINGLE SCREW</td>
            <td>Ilość nici</td>
            <td>cena zabiegu</td>
          </tr>
          <tr>
            <td rowspan="5" />
            <td>10 sztuk (5 na jedną stronę)</td>
            <td>600.00 </td>
          </tr>
          <tr>
            <td>20 sztuk (10 na 1 stronę)</td>
            <td>1,200.00 </td>
          </tr>
          <tr>
            <td>40 sztuk ( 2o na 1 stronę)</td>
            <td>2,400.00 </td>
          </tr>
          <tr>
            <td>dodatkowa nić</td>
            <td>60.00 </td>
          </tr>
          <tr>
            <td>Niestandardowa ilość nici</td>
            <td>cenę ustala lekarz</td>
          </tr>
          <tr>
            <td colSpan="3" />
          </tr>

          <tr>
            <th colSpan="2">
              Cena MEZOTERAPII w połączeniu z zabiegiem nićmi PDO
            </th>
          </tr>

          <tr>
            <td colSpan="2">Rodzaj preparatu</td>
            <td>rabat</td>
          </tr>
          <tr>
            <td rowspan="2" />
            <td>koktajl</td>
            <td>-40 %</td>
          </tr>
          <tr>
            <td>kwas hialuronowy nieusieciowany</td>
            <td>- 20 %</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CennikMedycyna;
