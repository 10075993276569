import React from "react";
import CennikStom from "../components/Cennik/CennikStom";
import CennikMedycyna from "../components/Cennik/CennikMedycyna";
import TitleHeader from "../components/TitleHeader";

import Layout from "../components/layout"
// import SEO from "../components/seo"

const Cennik = props => {
  return (
    <Layout>
        <div className="cennik">
          <TitleHeader title="Cennik"/>
    
        
          <div className="content">
            <CennikStom />
            <CennikMedycyna />
            <p>
              * ceny podane w złotych polskich, PLN
            </p>
          </div>
        </div>
    </Layout>
  );
  // }
};

export default Cennik;
