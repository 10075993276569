import React from "react";

const CennikStom = props => {
  return (
    <div>
      <table className="tabelka cennik">
        <tbody>
          <tr>
            <th>
              <b>STOMATOLOGIA</b>
            </th>
            <th className="center">
              <b>CENA*</b>
            </th>
          </tr>
          <tr>
            <td>Przegląd</td>
            <td>50</td>
          </tr>
          <tr>
            <td>Konsultacja</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Kosztorys leczenia</td>
            <td>50</td>
          </tr>
          <tr>
            <td>
              Pakiet diagnostyczny/plan leczenia – 2 wizyty (badanie,
              konsultacja, zdjęcie pantomograficzne, modele diagnostyczne,
              przygotowanie planu leczenia)
            </td>
            <td>290</td>
          </tr>
          <tr>
            <td>
              Projektowanie uśmiechu (badanie, konsultacja, wykonanie i analiza
              prototypu „nowego uśmiechu”)
            </td>
            <td>450</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">STOMATOLOGIA ZACHOWAWCZA</th>
          </tr>
          <tr>
            <td>Wypełnienie kompozytowe</td>
            <td>190-280</td>
          </tr>
          <tr>
            <td>Odbudowa zęba pod pracę protetyczną</td>
            <td>190</td>
          </tr>
          <tr>
            <td>Opatrunek tymczasowy</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Estetyczna korekta kształtu zęba materiałem kompozytowym</td>
            <td>420</td>
          </tr>
          <tr>
            <td>
              Odbudowa zęba wkładem z włókna szklanego – ząb jedno korzeniowy
            </td>
            <td>290</td>
          </tr>
          <tr>
            <td>
              Odbudowa zęba wkładem z włókna szklanego – ząb wielokorzeniowy
            </td>
            <td>390</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">LECZENIE ENDODONTYCZNE</th>
          </tr>
          <tr>
            <td>Interwencja endodontyczna (doraźna pomoc w razie bólu)</td>
            <td>190</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne – ząb jednokanałowy</td>
            <td>490</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne – ząb dwukanałowy</td>
            <td>690</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne – ząb trzykanałowy</td>
            <td>890-1050</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne powtórne – ząb jednokanałowy</td>
            <td>550</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne powtórne – ząb dwukanałowy</td>
            <td>790</td>
          </tr>
          <tr>
            <td>Leczenie endodontyczne powtórne – ząb trzykanałowy</td>
            <td>980-1180</td>
          </tr>
          <tr>
            <td>Odbudowa zniszczonego zęba do leczenia endodntycznego</td>
            <td>190</td>
          </tr>
          <tr>
            <td>Usunięcie starego wkładu koronowo-korzeniowego</td>
            <td>190-290</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">ESTETYKA/PROTETYKA</th>
          </tr>
          <tr>
            <td>Licówka kompozytowa</td>
            <td>680</td>
          </tr>
          <tr>
            <td>Licówka porcelanowa</td>
            <td>1580</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <td>Korona kompozytowa</td>
            <td>580</td>
          </tr>
          <tr>
            <td>Korona porcelanowa na metalu</td>
            <td>890</td>
          </tr>
          <tr>
            <td>Korona pełnoceramiczna</td>
            <td>1580</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <td>Inlay / Onlay / Overlay porcelanowy</td>
            <td>1090</td>
          </tr>
          <tr>
            <td>Inlay / Onlay / Overlay kompozytowy</td>
            <td>690</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <td>Tymczasowa korona / licówka</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Usunięcie starej korony</td>
            <td>100</td>
          </tr>
          <tr>
            <td>Proteza akrylowa częściowa (do 7 zębów)</td>
            <td>850</td>
          </tr>
          <tr>
            <td>Proteza akrylowa częściowa (do 8 zębów)</td>
            <td>1090</td>
          </tr>
          <tr>
            <td>Proteza szkieletowa</td>
            <td>1750</td>
          </tr>
          <tr>
            <td>Prace kombinowane </td>
            <td>wycena indywidualna na podstawie planu leczenia</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">IMPLANTOLOGIA / CHIRURGIA</th>
          </tr>
          <tr>
            <td>Implant</td>
            <td>od 2500</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <td>Podniesienie dna zatoki szczękowej</td>
            <td>od 2500</td>
          </tr>
          <tr>
            <td>Augumentacja</td>
            <td>od 1000</td>
          </tr>
          <tr>
            <td>Proste usunięcie zęba</td>
            <td>150</td>
          </tr>
          <tr>
            <td>Chirurgiczne usunięcie zęba</td>
            <td>250-450</td>
          </tr>
          <tr>
            <td>Korona na implancie – ceramiczna na łączniku indywidualnym</td>
            <td>2900</td>
          </tr>
          <tr>
            <td>Korona na implancie – stalowa na łaczniku standardowym</td>
            <td>2200</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">WYBIELANIE / HIGIENIZACJA / PROFILAKTYKA</th>
          </tr>
          <tr>
            <td>Wybielanie domowe – nakładkowe</td>
            <td>750</td>
          </tr>
          <tr>
            <td>Wybielanie w gabinecie – systemem Prevdent</td>
            <td>1250</td>
          </tr>
          <tr>
            <td>
              Wybielanie przebarwionego zęba po leczeniu kanałowym – pierwsza
              wizyta
            </td>
            <td>240</td>
          </tr>
          <tr>
            <td>
              Wybielanie przebarwionego zęba po leczeniu kanałowym – kolejna
              wizyta
            </td>
            <td>100</td>
          </tr>
          <tr>
            <td>Fluoryzacja kontaktowa</td>
            <td>120</td>
          </tr>
          <tr>
            <td>
              Higienizacja jamy ustnej (usunięcie kamienia, piaskowanie,
              fluoryzacja)
            </td>
            <td>250</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">RTG</th>
          </tr>
          <tr>
            <td>rtg punktowe</td>
            <td>30</td>
          </tr>
          <tr>
            <td>rtg pantomograficzne</td>
            <td>80</td>
          </tr>
          <tr>
            <td>rtg cefalometryczne</td>
            <td>80</td>
          </tr>
          <tr>
            <td>cbct – endo ct</td>
            <td>150</td>
          </tr>
          <tr>
            <td />
            <td />
          </tr>
          <tr>
            <th colSpan="2">UDOSTĘPNIENIE DOKUMENTACJI MEDYCZNEJ</th>
          </tr>
          <tr>
            <td>Odpis za jedną stronę</td>
            <td>9,03</td>
          </tr>
          <tr>
            <td>Kopia/Wydruk za jedną stronę</td>
            <td>0,32</td>
          </tr>
          <tr>
            <td>
              Dokumentacja na informatycznym nośniku danych(całość dokumentacji)
            </td>
            <td>1,81</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CennikStom;
